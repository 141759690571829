.switchContainer {
  position: relative;
  display: flex;
  flex-direction: column;
}

.switchContainer > label {
  position: absolute;
  top: -0.45rem;
  left: 0.85rem;
  font-size: 0.6rem;
  line-height: 0.75rem;
}

.switch {
  position: relative;
  margin-top: 0.45rem;
  width: 100%;
  border-radius: 0.25rem;
  overflow: hidden;
  display: grid;
  flex-wrap: nowrap;
}

.switchLabel {
  position: relative;
  float: left;
  flex: 1;
  padding: 0 0.25rem;
  line-height: 1.75rem;
  font-size: 0.95rem;
  text-align: center;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  /* font-family: 'Roboto Mono' !important; */
}
