.loginCard {
  background-color: #fff;
  border-radius: 1rem;
  padding: 2.5rem;
  width: 100%;
  max-width: 34rem;
  position: relative;
  margin-top: 5rem;
}

.logo {
  position: absolute;
  top: -7rem;
  left: 43%;
}

.input {
  background-color: var(--lt-color-gray-200);
  border: none;
  padding: 0.75rem 1.25rem;
  border-radius: 0.5rem;
  width: 100%;
  font-size: 0.865rem;
  margin-top: 0.75rem;
}

.input::placeholder {
  font-weight: 500;
  color: var(--lt-color-gray-600);
}

.input:focus-within {
  background-color: #fff;
  outline-color: var(--theme-primary);
}

.forgotPassword {
  color: var(--lt-color-gray-600);
  font-size: 0.75rem;
}

.tabs {
  display: flex;
  width: 100%;
  justify-content: space-around;
  border-bottom: var(--lt-color-gray-300) 2px solid;
  padding-bottom: 1rem;
}

.tabs .tab {
  color: var(--lt-color-gray-600);
  cursor: pointer;
  font-size: large;
}

.tabs .tab--active {
  color: var(--lt-color-gray-700);
  font-weight: 500;
  position: relative;
  display: flex;
}

.tabs .tab--active::after {
  position: absolute;
  content: '';
  width: 200%;
  height: 2px;
  background-color: var(--theme-primary);
  bottom: -1.15rem;
  left: -50%;
}

.sidebyside{
  display: flex;
  flex-direction: row;
}