.title {
  position: relative;
  padding-left: 0.5rem;
}

.title::after {
  position: absolute;
  content: '';
  width: 0.25rem;
  height: 100%;
  border-radius: 2px;
  background: var(--theme-primary);
  top: 0;
  left: -0.25rem;
}
