.select {
  font-family: 'Roboto Mono' !important;
}

.select div.react-select__control {
  min-height: 32px;
}

.select > span {
  width: 0;
  height: 0;
  clip: 0;
}
