.sidebar {
  width: 64px;
  transition: width 300ms;
  font-family: 'Poppins', sans-serif;

  background-color: white;
  border-right: var(--lt-color-gray-400) solid 1px;
  overflow: hidden;

  color: #8e8ea4;
  /*position: absolute;*/
  /*z-index: 100;*/
  height: auto;
  /*min-height: 100%;*/
}

.sidebar:hover {
  width: 250px;
}

.sidebar:hover .item > a {
  width: 100%;
}

.sidebarHead {
  color: #8e8ea4;
  font-size: 14px;
  margin-left: 12px;
}

.sidebarItem {
  color: #8e8ea4;
  transition: ease-in-out 0.3s;
}

.sidebarItem:hover {
  color: var(--theme-primary);
}

.item > a {
  text-decoration: none;
  white-space: nowrap;
  display: inline-flex;
}

button.pinButton {
  margin-left: auto;
}

.item:hover .pin {
  display: initial;
}

.label {
  display: initial;
}

.sidebarActiveItem {
  color: var(--theme-primary);
}

.sidebarActiveItem:hover {
  color: var(--theme-primary);
}

.selector {
  position: absolute;
  width: 3px;
  height: 32px;
  background: var(--theme-primary);
  margin-top: -3px;
  border-radius: 0px 100px 100px 0px;
}

.pin {
  display: none;
  color: #8e8ea4;
  float: right;
  /*margin-right: 0px;*/
  /*margin-top: 5px;*/
}

.pin:hover {
  color: #5e5e6e;
}

.pinned {
  float: right;
  /*margin-right: 25px;*/
  /*margin-top: 5px;*/
  color: var(--theme-primary);
}

@media only screen and (max-width: 768px) {
  .sidebar {
    width: 64px;
  }
  .sidebarHead {
    margin-left: 10px;
  }
  .label {
    display: none;
  }
}
