@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  accent-color: var(--theme-primary);
}

.rmsc {
  --rmsc-main: var(--theme-primary) !important;
  --rmsc-hover: rgba(var(--theme-primary-rgb), 0.5) !important;
  --rmsc-selected: rgba(var(--theme-primary-rgb), 0.75) !important;
  --rmsc-border: #ccc !important;
  --rmsc-gray: #aaa !important;
  --rmsc-bg: #fff !important;
  --rmsc-p: 0.5rem; /* Spacing */
  --rmsc-radius: 4px; /* Radius */
  --rmsc-h: 38px; /* Height */
}

body {
  font-family: 'Roboto', sans-serif;
}

button {
  padding: 0;
  background: transparent;
  border: none;
  cursor: pointer;
}

.buttonLandingPage {
  height: 150px;
  width: 580px;
  padding: 20px 20px 20px 100px;
  background-color: white;
  border: 2px solid #422800;
  border-radius: 30px;
  box-shadow: #422800 4px 4px 0 0;
  color: #422800;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin: 5px -10px 10px -20px;
  stroke: #fff;
  stroke-dasharray: 150 480;
  stroke-dashoffset: 150;
  transition: 1s ease-in-out;
}

.buttonLandingPage:hover {
  background-color: #fff;
}

.buttonLandingPage:active {
  box-shadow: #422800 2px 2px 0 0;
  transform: translate(2px, 2px);
}

/* CUSTOM STYLES */

.buttonLandingPage svg {
  font-size: 150px;
  margin-block-end: 10px;
  margin-left: -60px;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-wrapper .react-datepicker__input-container {
  width: 100%;
}

.react-datepicker-wrapper .react-datepicker__input-container > input {
  width: 100%;
}

input[type='number'] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.rmsc.multi-select .dropdown-heading > .dropdown-heading-value > span.gray {
  display: none !important;
}

.react-tabs__tab-list {
  margin-bottom: 0;
}

.react-tabs__tab-panel {
  padding: 0.75rem 0;

  border: solid 1px #aaa;
  border-top: none;
  border-radius: 0 0 5px 5px;
}

.ag-row.ag-row-level-0.ag-row-even {
  background-color: gainsboro !important;
}

.ag-theme-alpine .ag-row .ag-cell {
  line-height: 19px !important;
}
