.container {
  position: 'relative';
  display: flex;
  flex-direction: column;
}

.label {
  padding: 0 0.5rem;
  pointer-events: none;
  font-size: 0.9rem;
  color: var(--lt-color-gray-600);
  background-color: white;

  position: absolute;
  transform: translate(0.5rem, 0.5rem) scale(1);

  transform-origin: top left;
  transition: all 0.2s ease-out;
}

.container:focus-within label.label {
  transform: translate(0.5rem, -0.5rem) scale(0.75);
  color: var(--theme-primary);
}

.container label.active {
  transform: translate(0.5rem, -0.5rem) scale(0.75);
}
