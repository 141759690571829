.containerInput {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.containerInput select:focus-within,
.containerInput input:focus-within {
  border: solid 2px var(--theme-primary);
}

.containerInput > label {
  padding: 0 0.5rem;
  pointer-events: none;
  font-size: 0.8rem;
  color: var(--lt-color-gray-600);
  background-color: white;

  position: absolute;
  transform: translate(0.5rem, 0.55rem) scale(1);

  transform-origin: top left;
  transition: all 0.2s ease-out;
}

.containerInput:focus-within label {
  transform: translate(0.5rem, -0.5rem) scale(0.75);
  color: var(--theme-primary);
}

.containerInput .active {
  transform: translate(0.5rem, -0.5rem) scale(0.75);
}
